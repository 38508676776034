import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
// import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"
import { Box, Text, Grid } from "theme-ui"
import { Typer } from "../components/indextypewriter"
import { animated, useTransition, useSpring, config } from "react-spring"
import "../styles/style.css"

const IndexPage = ({ data }) => {
  const fade = useSpring({
    config: config.gentle,
    from: {
      opacity: 0.01,
      transform: "perspective(600px) translate3d(0,-5px,0)",
    },
    to: async next => {
      await next({
        opacity: 1,
        transform: "perspective(600px) translate3d(0,0px,0)",
      })
    },
  })
  const AnimatedBox = animated(Box)
  // const transitions = useTransition(data.allStrapiProject.nodes, item => item.slug, {
  const transition = useTransition(data.allStrapiProject.nodes, {
    key: item => item.slug,
    config: config.gentle,
    trail: 150,
    from: { opacity: 0.01, transform: "perspective(600px) rotateX(90deg)" },
    enter: { opacity: 1, transform: "perspective(600px) rotateX(0deg)" },
    leave: { opacity: 0.01, transform: "perspective(600px) rotateX(90deg)" },
  })

  const whatami = data.strapiFrontpage.ima
  const whatyouare = Object.values(whatami).map(value => value.ima)

  return (
    <Layout>
      <SEO title={"👋"} />
      <AnimatedBox style={fade} sx={{ fontSize: [3, 4, 5], my: "5" }}>
        <Text
          sx={{
            fontWeight: "light",
            lineHeight: "body",
            display: "inline",
          }}
        >
          {data.strapiFrontpage.hello}
          <Typer
            fontFamily={"body"}
            fontWeight={"body"}
            strings={whatyouare}
          />
        </Text>
        <Text
          sx={{
            fontWeight: "light",
            lineHeight: "body",
            display: "block",
          }}
        >
          You can reach me at
          <Text
            px={2}
            pb={1}
            sx={{
              color: "primary",
              fontWeight: "body",
              lineHeight: "body",
              display: "inline",
            }}
          >
            {data.strapiFrontpage.email}
          </Text>
        </Text>
      </AnimatedBox>
      <Grid
        gap="3"
        columns={["1fr 1fr", "1fr 1fr 1fr",  "1fr 1fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr"]}
      >
        {transition((style, item) => {
          return (
            <AnimatedBox
              style={style}
              id="projectBox"
              sx={{ position: "relative" }}
              key={item.slug}
            >
              <Link to={`/projects/${item.slug}`}>
                <Img
                  css={{ width: "100%", height: "100%", borderRadius: 5 }}
                  fixed={item.cover.childImageSharp.fixed}
                />
                <Box sx={{
                  // filter: 'drop-shadow(-1px 2px 2px rgba(50, 50, 0, 0.5))'
                  }}>
                <Box
                  sx={{
                    // transform: `rotateZ(${Math.floor(
                    //   Math.random() * 3 - Math.random() * 3
                    // )}deg)`,
                    // backgroundColor: 'postit',
                    p: '2',
                    color: 'text',
                    position: "relative", top: "-10px", left: "-5px",
                    margin: 'auto',
                    clipPath: "polygon(0 0, 100% 0, 100% 90%, 95% 100%, 0 100%)",
                    width: '95%',
                  }}
                >
                  <Text
                    as={"span"}
                    sx={{
                      fontFamily: "heading",
                      lineHeight: "heading",
                      fontSize: "4",
                    }}
                  >
                    {item.title}
                  </Text>
                  <Text
                    sx={{
                      fontWeight: "body",
                      textTransform: "uppercase",
                      fontSize: "1",
                    }}
                  >
                    {item.category}
                  </Text>
                </Box>
                </Box>
              </Link>
            </AnimatedBox>
          )
        })}
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        author
      }
    }
    strapiFrontpage {
      hello
      email
      ima {
        ima
      }
    }
    allStrapiProject(sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        title
        category
        client
        date(formatString: "YYYY")
        description
        cover {
          childImageSharp {
            fixed(width: 200, height: 200, cropFocus: CENTER, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
